.verifyRowedit {
    margin-top: 20px;
    border: 1px solid #855bf5;
    border-radius: 5px;
    padding: 10px;
    background-color: #855bf533;
    display: flex;
    flex-direction: column;
    /* min-width: 700px; */
    /* max-width: 700px; */
}

.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 10px;
}

.col_flex {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.numberTitle {
    font-size: 11px;
    margin-bottom: 5px;
    color: gray;
}

.tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.thumb1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.images-row {
    width: 93%;
    margin-top: 20px;
}

.thumbnail {
    height: 100px;
    width: 100px;
    border: 1px solid gray;
    border-radius: 10px;
    margin-bottom: 10px;
    margin: 5px 5px 0px;
}

@media (max-width: 730px) {
    .verifyRowedit {
        min-width: 80vw;
        margin-top: 20px;
        border: 1px solid #855bf5;
        border-radius: 5px;
        padding: 10px;
        background-color: #855bf533;
        display: flex;
    }
}

.css-9hlf0y-MuiPaper-root{
    background: none !important;
}

.css-11rrhx6-MuiPaper-root{
    background: none !important;
}



/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

