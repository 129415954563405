.mainGridLogin{
    width:100%;
    text-align:center;
    margin-top: 150px;

}

.mbnumberTextfield{
    width: 300px;
}

.buttonLogin{
    width:300px;
    background: #673695 !important;


}
.mobileNumberHeading{
    color: #673695;
}

.mainImagedim{
    height: 300px !important;
    width: 300px !important;
}