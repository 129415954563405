.dashboardCardHeader{
    margin-top: 150px;
    display: flex;
    justify-content: space-around;
    width:'100%'
}

.cardInsideDashboard{
    /* border: 1px solid black; */
    display: flex;
    gap:10px;
    width:250px;
    height: 100px;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
}